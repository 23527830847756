'use client'

import { useFormStatus } from 'react-dom'
import { event } from 'nextjs-google-analytics'

import Button, { ButtonProps } from './Button'

// Extended props to include optional GA tracking
type SubmitButtonProps = ButtonProps & {
  type?: 'submit'
  eventName?: string // GA event name, if tracking is required
  eventParams?: Record<string, any> // Additional GA event parameters
}

const SubmitButton = ({
  children,
  onClick,
  eventName,
  eventParams,
  ...props
}: SubmitButtonProps) => {
  const { pending } = useFormStatus()

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    // Log GA event if the eventName is provided
    if (eventName) {
      event(eventName, eventParams)
    }
    if (onClick) {
      onClick(e)
    }
  }

  return (
    <Button
      {...props}
      type="submit"
      aria-label="Submit"
      isLoading={pending}
      onClick={handleClick}
    >
      {children}
    </Button>
  )
}

export default SubmitButton
